import { useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { WallModel } from '@model/wall.model';
import GridContainer from '@atoms/GridContainer/GridContainer';
import Row from '@atoms/CustomGrid/Row';
import Grid from '@mui/material/Grid';
import { useColors } from '@hooks/useColors';
import styles from './WallParagraph.module.scss';
import Marquee from 'react-fast-marquee';
import Box from '@mui/material/Box';
import CustomButton from '@atoms/CustomButton/CustomButton';
import Image from 'next/image';
import backgroundImageLight from '/public/assets/images/we-build-background-light.svg';
import backgroundBallLight from '/public/assets/images/background-ball-light.svg';
import FadeIn from '@atoms/FadeIn';
import RoundedIconButton from '@atoms/RoundedIconButton/RoundedIconButton';
import { useTranslation } from 'next-i18next';
import IntroParagraph from '@organisms/IntroParagraph/IntroParagraph';
import { getFieldLink } from '@utils/functions/getFieldLink';
import BackgroundTransitionBox from '@atoms/BackgroundTransitionBox/BackgroundTransitionBox';
import theme from '@mui/theme';
import { absoluteUrl, transformTitleId } from '@utils/functions/utils';
import { getContentCategoryIcon } from '@utils/functions/contentType';
import WallParagraphMarqueeItem from '@organisms/WallParagraph/WallParagraphMarqueeItem';

const LinkChildren = dynamic(() => import('@atoms/LinkChildren/LinkChildren'));
const BackgroundText = dynamic(() => import('@atoms/BackgroundText/BackgroundText'));

const MARQUEE_SPEED = 40;

const WallParagraph = ({ fieldParagraph, node }: FieldParagraphProps) => {
  // const {
  //   mappedData: { data, isLoaded },
  // } = useMapper<any, WallModel>({
  //   backendData: fieldParagraph,
  //   mockDataUrl: fieldParagraph.mockDataUrl || '/data/scrolling-wall.json',
  //   mockDataMapper: (setData, mockData) => {
  //     setData({
  //       ...mockData,
  //       backgroundTransition: fieldParagraph.backgroundTransition,
  //     });
  //   },
  //   realDataMapper: (setData, backendData) => {
  //     setData({
  //       direction: backendData.behavior_settings?.style_options?.vertical_lines?.css_class || 'middle',
  //       title: backendData.field_title,
  //       cta: getFieldLink(backendData.field_link),
  //       backgroundTransition: backendData.behavior_settings?.style_options?.transition?.css_class,
  //       backgroundText: '', // todo
  //       // todo uncomment next line when data is ok
  //       // colorVariant: backendData.behavior_settings?.style_options?.color_scheme?.css_class
  //       colorVariant: 'light',
  //       items: backendData.field_items.map((item) =>
  //         item.view?.results.map((viewItem) => ({
  //           url: viewItem.path?.alias,
  //           isExternal: false,
  //           label: viewItem.title,
  //           variant: 'background-with-icon',
  //           iconName: 'article',
  //           imageUrl: absoluteUrl(viewItem.field_media?.field_media_image?.uri?.url),
  //         }))
  //       ),
  //       overline: backendData.field_overline,
  //       anchorData: backendData.behavior_settings?.style_options?.toc?.label || '',
  //     });
  //   },
  // });

  const data = useMemo(() => {
    const newData: WallModel = {
      direction: fieldParagraph.behavior_settings?.style_options?.vertical_lines?.css_class || 'middle',
      title: fieldParagraph.field_title,
      cta: getFieldLink(fieldParagraph.field_link),
      backgroundTransition: fieldParagraph.behavior_settings?.style_options?.transition?.css_class,
      backgroundText: '', // todo
      colorVariant: fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class,
      items: fieldParagraph.field_items.map((item, indexItem) =>
        item.view?.results.map((viewItem) => ({
          url: viewItem.path?.alias,
          isExternal: false,
          label: viewItem.title,
          variant: 'background-with-icon',
          iconName: getContentCategoryIcon(viewItem?.field_category?.field_machine_name),
          imageUrl: absoluteUrl(viewItem.field_media?.field_media_image?.uri?.url),
          itemsToDisplay: item.field_view?.resourceIdObjMeta?.items_to_display || '50',
        }))
      ),
      overline: fieldParagraph.field_overline,
      anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
    };

    return newData;
  }, [fieldParagraph]);

  const [marqueeZIndexes, setMarqueeZIndexes] = useState({ topMarqueeZIndex: 2, bottomMarqueeZIndex: 2 });

  const { t } = useTranslation();
  const [isMarqueePaused, setIsMarqueePaused] = useState<boolean>(false);
  const { backgroundColor } = useColors(data?.colorVariant);

  const [topMarqueeItems, bottomMarqueeItems] = useMemo(() => {
    if (!data) return [];

    return data.items.map((marqueeItems, i) => marqueeItems?.slice(0, parseInt(marqueeItems?.[i]?.itemsToDisplay, 10)));
  }, [data]);

  if (!data) return null;

  return (
    <>
      <noscript>
        <div>
          {[...topMarqueeItems, ...bottomMarqueeItems].map((mItem) => (
            <LinkChildren
              key={`${mItem?.url}_${mItem?.title}`}
              className="_"
              target={mItem?.target}
              isExternal={mItem?.isExternal}
              url={mItem?.url}
            />
          ))}
        </div>
      </noscript>

      <BackgroundTransitionBox
        anchor-data={transformTitleId(data.anchorData || '')}
        linesDirection={data.direction}
        transition={data.backgroundTransition}
        colorVariant={data.colorVariant}
      >
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            py: { xs: theme.spacing(80), lg: theme.spacing(104) },
          }}
        >
          <FadeIn>
            <GridContainer>
              <Row>
                <Grid item xs={0} lg={1} />
                <Grid item xs={12} lg={10}>
                  <IntroParagraph
                    node={node}
                    removePadding={true}
                    isTransitionBoxDisabled={true}
                    isBackgroundColorDisabled={true}
                    dataOverride={{ cta: null }}
                    mockDataUrl={fieldParagraph.mockDataUrl}
                    fieldParagraph={fieldParagraph}
                  />
                </Grid>
              </Row>
            </GridContainer>

            <Box position="relative" mt={{ xs: 96, lg: 120 }}>
              <Box position="absolute" right={{ xs: 22, lg: 24 }} top={{ xs: -56, lg: -108 }}>
                <RoundedIconButton
                  label={t(isMarqueePaused ? 'marqueePauseButton.play' : 'marqueePauseButton.pause')}
                  iconName={isMarqueePaused ? 'play_arrow' : 'pause'}
                  colorVariant={data.colorVariant}
                  onClick={() => setIsMarqueePaused((isPaused) => !isPaused)}
                />
              </Box>

              <Box
                sx={{ position: 'absolute', top: { xs: -26, lg: -80 }, right: { xs: 22, lg: 164 } }}
                width={{ xs: 88, lg: 176 }}
                height={{ xs: 88, lg: 176 }}
              >
                <Image fill src={backgroundBallLight} alt=""></Image>
              </Box>
            </Box>

            <Box
              zIndex={marqueeZIndexes.topMarqueeZIndex}
              position="relative"
              onMouseEnter={() => setMarqueeZIndexes({ topMarqueeZIndex: 2, bottomMarqueeZIndex: 1 })}
            >
              {data.backgroundText && <BackgroundText text={data.backgroundText} />}
              <Marquee className={styles.Marquee} play={!isMarqueePaused} speed={MARQUEE_SPEED} gradient={false}>
                {topMarqueeItems?.map((mItem) => (
                  <WallParagraphMarqueeItem {...mItem} key={`${mItem?.url}_${mItem?.title}_top`} />
                ))}
              </Marquee>
            </Box>

            <Box
              onMouseEnter={() => setMarqueeZIndexes({ topMarqueeZIndex: 1, bottomMarqueeZIndex: 2 })}
              position="relative"
              zIndex={marqueeZIndexes.bottomMarqueeZIndex}
              mb={{ xs: 48, lg: 81 }}
              mt={{ xs: 12, lg: 24 }}
            >
              <Marquee
                className={styles.Marquee}
                play={!isMarqueePaused}
                speed={MARQUEE_SPEED}
                gradient={false}
                direction="right"
              >
                {bottomMarqueeItems?.map((mItem) => (
                  <WallParagraphMarqueeItem {...mItem} key={`${mItem?.url}_${mItem?.title}_bottom`} />
                ))}
              </Marquee>
            </Box>

            {data.cta && (
              <GridContainer>
                <Row>
                  {/* <Grid item lg={5} xs={0}></Grid> */}
                  <Grid item xs={12} textAlign="center">
                    <CustomButton
                      sx={{ margin: '0 auto', display: 'block', width: 'fit-content' }}
                      text={data.cta.label}
                      href={data.cta.url}
                      component="a"
                      // variant="bodyL"
                      variant="large"
                      fullWidth={false}
                      // color={data.colorVariant === 'dark' ? 'secondaryOnDark' : 'secondary'}
                      color="primary"
                      external={data.cta.isExternal}
                    />
                  </Grid>
                </Row>
              </GridContainer>
            )}

            <Box
              width={{ xs: 281, lg: 495 }}
              height={{ xs: 300, lg: 527 }}
              zIndex={0}
              sx={{ position: 'absolute', top: { xs: 357, lg: 415 }, left: { xs: -150, lg: 106 } }}
            >
              <Image fill src={backgroundImageLight} alt="" />
            </Box>
          </FadeIn>
        </Box>
      </BackgroundTransitionBox>
    </>
  );
};

export default WallParagraph;
