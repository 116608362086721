import { CardVariant } from '@atoms/CustomCard/CustomCard.props';
import { IconName } from '@model/icon-name.model';

export const getContentCategoryIcon = (category: string): IconName => {
  switch (category) {
    case 'articoli':
      return 'article';

    case 'fotonews':
      return 'perm_media';

    case 'gallery':
      return 'perm_media';

    case 'photogallery':
      return 'perm_media';

    case 'video':
      return 'movie';

    case 'podcast':
      return 'podcasts';

    // TODO: check if is correct category
    case 'news-and-press':
      return 'article';

    // File type
    case 'pdf':
      return 'picture_as_pdf';

    default:
      return 'topic';
  }
};

export const getContentCard = (category: string): CardVariant => {
  switch (category) {
    case 'articoli':
    case 'photogallery':
      return 'background-with-icon';

    case 'podcast':
      return 'podcast-card';

    case 'video':
      return 'video-card';

    default:
      return 'background-with-icon';
  }
};
