import { FC } from 'react';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import { WallListItem } from '@model/wall.model';

const CustomCard = dynamic(() => import('@atoms/CustomCard/CustomCard'));
const LinkChildren = dynamic(() => import('@atoms/LinkChildren/LinkChildren'));

const WallParagraphMarqueeItem: FC<WallListItem> = ({ target, isExternal, url, ...props }) => {
  return (
    <LinkChildren target={target} isExternal={isExternal} url={url}>
      <CustomCard
        {...props}
        sx={{
          ':hover': {
            boxShadow: theme.boxShadows.elevationLight600,
            transform: 'scale(1.4) !important',
          },
          position: 'relative',
          transition: 'transform 400ms ease, boxShadow 400ms ease',
          marginRight: { xs: 8, lg: 24 },
          width: { xs: 154, lg: 288 },
        }}
      />
    </LinkChildren>
  );
};

export default WallParagraphMarqueeItem;
